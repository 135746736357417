import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'

// Icon
import favicon from 'img/favicon.png'

export interface SeoProps {
  opengraphImage: {
    sourceUrl: string
  }
  title: string
  metaDesc: string
  canonical: string
  opengraphTitle: string
  opengraphUrl: string
}

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

const SEO: React.FC<{ seo: SeoProps }> = ({ seo }) => {
  const {
    site,
    wpComponent,
    // @ts-ignore
  } = useStaticQuery<GatsbyTypes.socialSharingQueryQuery>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`
    : ''

  const image: string = seo.opengraphImage
    ? seo.opengraphImage.sourceUrl
    : defaultImageUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: 'nl',
      }}
      title={removeHTML(seo.title)}
    >
      {/* Default */}
      {seo.metaDesc && (
        <meta name="description" content={removeHTML(seo.metaDesc)} />
      )}
      {seo.canonical && (
        <link rel="canonical" href={removeHTML(seo.canonical)} />
      )}
      <meta name="image" content={image} />
      <link rel="icon" href={favicon} />

      {/* SEO */}
      {seo.opengraphTitle && (
        <meta property="og:type" content={seo.opengraphTitle} />
      )}
      {seo.opengraphUrl && (
        <meta property="og:url" content={seo.opengraphUrl} />
      )}
      {seo.title && (
        <meta property="og:title" content={removeHTML(seo.title)} />
      )}
      {seo.metaDesc && (
        <meta property="og:description" content={removeHTML(seo.metaDesc)} />
      )}
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="nl_NL" />
      {/* Leadinfo */}
      <script>
        {`
      (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
      l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
      l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
      }(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-61E565CCA61EE"));
      `}
      </script>

      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="4ad07af0-694d-4f9c-bd4e-657f1afd128d"
        type="text/javascript"
        async
      />
    </Helmet>
  )
}

export default SEO
