const mainTheme = {
  color: {
    primary: '#FCCF14',
    secondary: '#082E53',
    grey: '#989898',
    dark: '#1C1C1C',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'Century Gothic, Arial',
      secondary: 'Century Gothic, Arial',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '20px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    lineheight: {
      tiny: '18px',
      small: '22px',
      medium: '24px',
      big: '26px',
      large: '32px',
      huge: '38px',
      mega: '48px',
      giga: '68px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
