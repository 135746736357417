import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ hoverable: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.color.light};
  border-radius: 25px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  transition: box-shadow 0.3s ease-in-out;
  @media (min-width: 576px) {
    padding: 2.5rem;
  }
  @media (max-width: 575px) {
    padding: 2rem;
  }

  &:hover {
    ${(props) =>
      props.hoverable &&
      css`
        box-shadow: unset;
      `}
  }
`

interface BlockPrimaryProps {
  hoverable?: boolean
  className?: string
}

const BlockPrimary: React.FC<BlockPrimaryProps> = ({
  children,
  hoverable = false,
  className = '',
}) => (
  <Wrapper hoverable={hoverable} className={className}>
    <>{children}</>
  </Wrapper>
)

export default BlockPrimary
