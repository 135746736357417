/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import NavLink from 'components/shared/NavLink'
import LinkSecondary from 'components/elements/Links/LinkSecondary'

// Images
import City from 'img/city.inline.svg'
import Pin from 'img/pin.inline.svg'
import Mail from 'img/mail.inline.svg'
import Phone from 'img/phone-secondary.inline.svg'
import Plaatjie from '@ubo/plaatjie'

const StyledFooter = styled.div`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  background-color: rgba(8, 45, 83, 0.92);
  color: ${(props) => props.theme.color.light} !important;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;

  & a {
    color: ${({ theme }) => theme.color.light} !important;
  }

  @media (min-width: 576px) {
    padding-left: 3rem;
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 575px) {
    padding: 1.5rem;
  }
`

const Certificates = styled.div`
  & a:hover {
    opacity: 0.75;
  }
`

const BackgroundWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  & svg {
    width: 100%;
    height: 100%;
  }
`

const Heading = styled(NavLink)`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.light} !important;

  &:hover {
    text-decoration: underline;
  }
`

const Row = styled.div`
  position: relative;

  & .sublink {
    font-size: ${({ theme }) => theme.font.size.small};
  }

  & a:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`

const Documents = styled.div`
  & > a {
    margin-right: 15px;
  }

  & .document:before {
    content: '|';
    color: ${({ theme }) => theme.color.primary};
    margin-right: 15px;
  }
`

const Image = styled(Plaatjie)`
  height: 111px;
  width: 100%;

  & img {
    object-fit: contain !important;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
          contactFooter {
            link {
              title
              url
            }
            address
            area
            mail {
              title
              url
            }
            phone {
              title
              url
            }
          }
          certificatesFooter {
            certificate {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 300)
                }
              }
            }
            link {
              title
              url
            }
          }
          documentsFooter {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  const {
    documentsFooter,
    certificatesFooter,
    contactFooter,
    menuFooter,
  }: any = fields?.footer

  const date = new Date()

  return (
    <div className="container">
      <StyledFooter>
        <BackgroundWrapper>
          <City />
        </BackgroundWrapper>
        <Row className="row">
          <div className="col-sm-6 mb-4">
            <div className="row">
              {menuFooter.map((item: any, index: number) => (
                <div className="col-lg-6 mb-4" key={index}>
                  <Heading to={item.link.url} key={index}>
                    {item.link.title}
                  </Heading>
                  <div>
                    {item.submenu.map((subitem: any, subIndex: number) => (
                      <div key={subIndex}>
                        <LinkSecondary
                          to={subitem.link.url}
                          className="sublink"
                        >
                          {subitem.link.title}
                        </LinkSecondary>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <Documents className="d-flex flex-column flex-sm-row justify-content-around align-items-start mt-5 mb-4 mb-sm-0">
              <NavLink to="/">{`Open-ICT ${date.getFullYear()} ©`}</NavLink>
              {documentsFooter.map((document: any, index: number) => (
                <div key={index}>
                  <NavLink
                    key={index}
                    to={document.link.url}
                    className="document"
                  >
                    {document.link.title}
                  </NavLink>
                </div>
              ))}
              <div>
                <button
                  type="button"
                  key="cookiebot-link"
                  onClick={() => Cookiebot.show()}
                  className="document text-white"
                >
                  Cookies
                </button>
              </div>
            </Documents>
          </div>
          <div className="col-sm-6 mb-4">
            <Heading to={contactFooter.link.url}>
              {contactFooter.link.title}
            </Heading>
            <Row className="row mt-2">
              <div className="d-flex col-lg-6">
                <Pin />
                <div className="ms-2">
                  <p>{contactFooter.address}</p>
                  <p>{contactFooter.area}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center">
                  <Mail />
                  <a href={contactFooter.mail.url} className="ms-2">
                    {contactFooter.mail.title}
                  </a>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Phone />
                  <a href={contactFooter.phone.url} className="ms-2">
                    {contactFooter.phone.title}
                  </a>
                </div>
              </div>
            </Row>
            <Certificates className="row ms-sm-2 mt-5">
              {certificatesFooter.map((certificate: any, index: number) => (
                <div
                  className={`col-4 col-lg${
                    index === certificatesFooter.length - 1 ? '' : '-3'
                  } mb-4`}
                  key={index}
                >
                  {certificate.link && (
                    <a
                      href={certificate.link.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image image={certificate.certificate} alt="" />
                      <span className="text-hidden">
                        {certificate.link.title}
                      </span>
                    </a>
                  )}
                  {!certificate.link && (
                    <Image image={certificate.certificate} alt="" />
                  )}
                </div>
              ))}
            </Certificates>
          </div>
        </Row>
      </StyledFooter>
    </div>
  )
}

export default Footer
