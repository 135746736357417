/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: loadable(
      () => import('components/flex/Banner/BannerShell')
    ),
    [`${prefix}_Flex_Form`]: loadable(
      () => import('components/flex/Form/FormShell')
    ),
    [`${prefix}_Flex_Privacy`]: loadable(
      () => import('components/flex/Misc/Privacy')
    ),
    [`${prefix}_Flex_Text`]: loadable(
      () => import('components/flex/Text/TextShell')
    ),
    [`${prefix}_Flex_Services`]: loadable(
      () => import('components/flex/Services/ServicesShell')
    ),
    [`${prefix}_Flex_UniqueSellingPoints`]: loadable(
      () => import('components/flex/Usp/UspShell')
    ),
    [`${prefix}_Flex_Slider`]: loadable(
      () => import('components/flex/Slider/SliderShell')
    ),
    [`${prefix}_Flex_Team`]: loadable(
      () => import('components/flex/Misc/Team')
    ),
    [`${prefix}_Flex_File`]: loadable(
      () => import('components/flex/Misc/File')
    ),
    [`${prefix}_Flex_Accordion`]: loadable(
      () => import('components/flex/Misc/Accordion')
    ),
    [`${prefix}_Flex_Downloads`]: loadable(
      () => import('components/flex/Misc/Downloads')
    ),
    [`${prefix}_Flex_PostTypes`]: loadable(
      () => import('components/flex/Posts/PostsShell')
    ),
    [`${prefix}_Flex_PreviewPostTypes`]: loadable(
      () => import('components/flex/Posts/Preview/PreviewShell')
    ),
    [`${prefix}_Flex_Fragment`]: loadable(
      () => import('components/flex/Fragment')
    ),
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
