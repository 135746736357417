/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink, { isPartiallyActive } from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Images
import Pointer from 'img/pointer.inline.svg'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Item = styled.li`
  list-style: none;

  & > a {
    color: ${(props) => props.theme.color.secondary};

    &[aria-current] {
    }

    &:hover {
      color: ${(props) => props.theme.color.primary};
    }
  }

  & svg {
    transform: rotate(-90deg);
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 18px;
      line-height: 18px;
      color: ${({ theme }) => theme.color.dark} !important;
    }
  }

  @media (max-width: 400px) {
    & > a {
      font-size: 16px;
      line-height: 16px;
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;
  background: white;
  border-radius: 25px;

  @media screen and (min-width: 992px) {
    position: absolute;
    overflow: hidden;
    top: 45px;
    min-width: 250px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.2));

    & > div {
      background-color: ${({ theme }) => theme.color.light};
      padding: 15px;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    top: 10px;
    padding-top: unset;
    width: 100%;
    padding: 1.5rem;
    max-width: 300px;
    margin: 0 auto;

    & a {
      color: ${({ theme }) => theme.color.grey};
    }
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => (
  <StyledSubmenu
    initial={{ height: 0 }}
    animate={{ height: 'fit-content' }}
    exit={{ height: 0 }}
    transition={{ duration: 0.05 }}
    className="d-flex flex-column align-items-center align-items-lg-start"
  >
    <div>
      {fields.map((f, index) => {
        const isPartiallyCurrent =
          typeof window !== `undefined` &&
          window.location.pathname.indexOf(f?.link?.url || '#') !== -1

        const isActive =
          isPartiallyActive({
            isPartiallyCurrent,
            location: typeof window !== `undefined` && window.location,
            href: f?.link?.url,
          })['aria-current'] === 'page'

        return (
          <Item key={index} className="d-flex align-items-center">
            <Pointer className="me-2" />
            <NavLink
              to={f?.link?.url || '/'}
              className={`${isActive ? 'font-weight-bold' : ''}`}
            >
              {f?.link?.title}
            </NavLink>
          </Item>
        )
      })}
    </div>
  </StyledSubmenu>
)

export default Submenu
