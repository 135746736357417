/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import NavLink from 'components/shared/NavLink'
import ButtonPrimarySmall from 'components/elements/Buttons/ButtonPrimarySmall'

// Images
import Phone from 'img/phone.inline.svg'
import Linkedin from 'img/linked.inline.svg'

const Item = styled.li`
  margin-left: 1rem;

  a:hover {
    cursor: pointer;
  }

  & .linkedin {
    width: 27px !important;
    height: 27px !important;
  }

  .supp {
    margin-bottom: 2px;
  }

  @media (max-width: 450px) {
    & .phone {
      padding: 3.2px 0;
      display: block !important;
      & svg {
        width: 9.5px;
        height: 9.5px;
      }
      & a {
        font-size: 10px;
        display: flex;
        align-items: flex-start;
      }
    }

    & .supp {
      & a {
        font-size: 10px;
        display: flex;
        align-items: flex-start;
      }

      padding: 3.2px 0;
      display: block !important;
    }
  }

  @media (max-width: 399px) {
    & .phone {
      padding: 5.4px 0;
      & a {
        font-size: 0;
      }
    }
  }
`

interface TopmenuProps {
  fields: GatsbyTypes.WpComponent
  location?: any
}

const Topmenu: React.FC<TopmenuProps> = ({ fields, location }) => {
  const { phone, linkedin, support }: any = fields.header?.topMenuHeader
  return (
    <>
      <Item>
        <ButtonPrimarySmall isCustom className="phone">
          <a href={phone.url} className="d-flex align-items-center">
            <Phone className="me-1 me-sm-2" />
            {phone.title}
          </a>
        </ButtonPrimarySmall>
      </Item>
      <Item>
        <ButtonPrimarySmall isCustom className="supp">
          <NavLink to={support.url}>{support.title}</NavLink>
        </ButtonPrimarySmall>
      </Item>

      <Item className="linkedin d-flex align-items-center">
        <a
          href={linkedin.url}
          className="text-hidden"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className="linkedin" />
          {linkedin.title}
        </a>
      </Item>
    </>
  )
}

export default Topmenu
