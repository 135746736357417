import React from 'react'
import { motion } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled, { css } from 'styled-components'
import NavLink from 'components/shared/NavLink'
import Topmenu from 'components/layout/Header/Topmenu'

// Images
import LogoDark from 'img/logo-dark.inline.svg'
import UK from 'img/united-kingdom.inline.svg'
import NL from 'img/netherlands.inline.svg'
import Circle from 'img/circle.inline.svg'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & ul {
    list-style: none;
    padding-right: 20px;
  }
`

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    padding-left: 0;
    margin: 0;
    width: 70%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.dark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

const Brand = styled(NavLink)`
  & > svg {
    height: auto;
    width: 175px;
  }
`

const Switch = styled(motion.a) <{ isOn: boolean }>`
  width: 50px;
  height: 24px;
  border-radius: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;


  ${(props) =>
    props.isOn
      ? css`
          justify-content: flex-end;
          @media (max-width: 450px) {
            padding: 10px 0;
          }
        `
      : css`
          justify-content: flex-start;
          /* @media (max-width: 450px) {
            background-color: lightgrey;
          } */
        `}
  background-color: ${({ theme }) => theme.color.primary};

  & > div {
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 200px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
  }

  @media (max-width: 450px) {
    width: 40px;
    height: 21px;

    & > div {
      width: 10px;
      height: 10px;
    }
  }
`

const SVGWrapper = styled.div`
  top: -110px;
  right: -50px;
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  location?: any
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({
  fields,
  location,
  setIsScrollable,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const [english, setEnglish] = React.useState(
    !!location.pathname.includes('/en')
  )

  return (
    <Wrapper className="d-block d-lg-none">
      <div className="container">
        <ul className="d-flex justify-content-end ps-0 pt-2 mb-0">
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: 1.9 }}
          >
            <span className="d-sm-inline d-md-none me-2">NL</span>
            <Switch
              animate
              isOn={english}
              onClick={() =>
                location.pathname.includes('/en')
                  ? setEnglish(true)
                  : setEnglish(false)
              }
              href={english ? '/' : '/en'}
            >
              <motion.div animate />
              <span className="ms-2 text-hidden">EN</span>
            </Switch>
            <span className="ms-2">EN</span>
          </div>
          <Topmenu fields={fields} location={location} />
        </ul>
        <div className="d-flex justify-content-between align-items-center py-3">
          <Brand to="/">
            <LogoDark />
          </Brand>
          {!english && (
            <NavButton
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(false)
              }}
            >
              <NavButtonStripe />
              <NavButtonStripe />
              <NavButtonStripe />
            </NavButton>
          )}
        </div>
      </div>

      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul className="position-relative">
            <SVGWrapper className="position-absolute">
              <Circle />
            </SVGWrapper>
            <Menu fields={fields} location={location} />
          </ul>
        </MenuWrapper>
      )}
    </Wrapper>
  )
}

export default Mobile
