/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled from 'styled-components'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  font-family: ${({ theme }) => theme.font.family.primary};

  & svg:hover {
    opacity: 0.75;
  }

  @media (min-width: 992px) {
    height: 140px;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  location?: any
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
  location,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
          topMenuHeader {
            phone {
              title
              url
            }
            support {
              title
              url
            }
            linkedin {
              title
              url
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledHeader isSticky={isSticky}>
      <Mobile
        fields={fields}
        setIsScrollable={setIsScrollable}
        location={location}
      />
      <Desktop fields={fields} isSticky={isSticky} location={location} />
    </StyledHeader>
  )
}

export default Header
