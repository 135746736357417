import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import Topmenu from 'components/layout/Header/Topmenu'
import Menu from 'components/layout/Header/Menu'
import NavLink from 'components/shared/NavLink'

// Third Party
import styled, { css } from 'styled-components'

// Images
import LogoDark from 'img/logo-dark.inline.svg'
import UK from 'img/united-kingdom.inline.svg'
import NL from 'img/netherlands.inline.svg'

const StyledDesktop = styled.div`
  & ul {
    list-style: none;
    padding-left: 0;
  }
`

const TopmenuWrapper = styled(motion.div)`
  background-color: #f2f2f2;
  height: 45px;
`

const MenuWrapper = styled.div<{ isSticky: boolean }>`
  height: 95px;
  background-color: ${({ theme }) => theme.color.light};

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    `}
`

const Brand = styled(NavLink)`
  & > svg {
    height: auto;
    width: 172px;
  }

  &:hover {
    opacity: 0.8;
  }
`

const Switch = styled(motion.a) <{ isOn: boolean }>`
  width: 50px;
  height: 24px;
  border-radius: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.primary};

  ${(props) =>
    props.isOn
      ? css`
          justify-content: flex-end;
          @media (min-width: 992px){
            padding: 10px 0;
          }
        `
      : css`
          justify-content: flex-start;
        `}

  & > div {
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 200px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
  }
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  location?: any
}

const Desktop: React.FC<DesktopProps> = ({ fields, location, isSticky }) => {
  const [english, setEnglish] = React.useState(
    !!location.pathname.includes('/en')
  )

  return (
    <StyledDesktop className="d-none d-lg-flex flex-lg-column ps-0 mb-0">
      <AnimatePresence>
        <TopmenuWrapper className="w-100">
          <div className="container h-100">
            <ul className="d-flex justify-content-end align-items-center h-100">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: 1.9 }}
              >
                <span className="me-2">NL</span>
                <Switch
                  animate
                  isOn={english}
                  onClick={() =>
                    location.pathname.includes('/en')
                      ? setEnglish(true)
                      : setEnglish(false)
                  }
                  href={english ? '/' : '/en'}
                >
                  <motion.div animate />
                  <span className="ms-2 text-hidden">EN</span>
                </Switch>
                <span className="ms-2">EN</span>
              </div>

              <Topmenu fields={fields} location={location} />
            </ul>
          </div>
        </TopmenuWrapper>
      </AnimatePresence>

      <AnimatePresence>
        <MenuWrapper
          className={`w-100 ${isSticky ? 'position-fixed' : 'position-relative'
            }`}
          isSticky={isSticky}
        >
          <div className="position-relative container h-100">
            <ul className="d-flex justify-content-between align-items-center h-100">
              <Brand to="/">
                <LogoDark />
              </Brand>

              <Menu fields={fields} location={location} />
            </ul>
          </div>
        </MenuWrapper>
      </AnimatePresence>
    </StyledDesktop>
  )
}

export default Desktop
