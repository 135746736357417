import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonTertiaryCSS = css`
  position: relative;
  background-color: ${(props) => props.theme.color.light};
  display: inline-block;
  border-radius: 50px;
  /* transition: background-color 0.3s ease-in-out; */
  z-index: 5;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.dark} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 8px 22px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
  }

  &:hover {
    background-color: #000;

    & > a,
    & > button {
      color: #fff !important;
    }
  }
`

const StyledButton = styled.span`
  ${ButtonTertiaryCSS};
`

interface ButtonTertiaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to?: string
  className?: string
  target?: string
}

const ButtonTertiary: React.FC<ButtonTertiaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonTertiary
