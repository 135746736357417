/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Pointer from 'img/pointer.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const Item = styled(motion.li)<{ submenu: boolean }>`
  position: relative;
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: 992px) {
      ${(props) =>
        props.submenu &&
        css`
          margin-top: 20px;
        `}
    }
  }

  @media screen and (min-width: 992px) {
    & div > a {
      color: ${(props) => props.theme.color.grey};
      font-size: ${({ theme }) => theme.font.size.medium};

      &[aria-current] {
        color: ${({ theme }) => theme.color.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};
      }

      &:hover {
        opacity: 0.7;
        color: ${({ theme }) => theme.color.secondary};
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;
    margin-top: 1rem;

    & div > a {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: 20px;

      &[aria-current] {
        font-weight: ${({ theme }) => theme.font.weight.bold};
      }
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
  location?: any
}

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-top: -4px;
  & > svg {
    transition: all 0.3s ease;
  }

  @media (max-width: 991px) {
    position: absolute;
    right: 15%;
    top: 2px;

    & > svg {
      & path {
        fill: ${({ theme }) => theme.color.dark};
      }
    }
  }
`

const Menu: React.FC<MenuProps> = ({ fields, location }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {!location.pathname.includes('/en') &&
        fields.header?.menuHeader?.map((l, index) => (
          <Item
            onHoverStart={() => setOpen(index)}
            onHoverEnd={() => setOpen(-1)}
            key={index}
            submenu={l?.submenu}
          >
            <div className="position-relative d-flex flex-column align-items-center">
              <NavLink to={l?.link?.url || '/'}>{l?.link?.title}</NavLink>
              {l?.submenu && (
                <SvgWrapper
                  open={open === index}
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                >
                  <Pointer />
                </SvgWrapper>
              )}
            </div>
            {l?.submenu && (
              <AnimatePresence>
                {open === index && <Submenu fields={l.submenu} />}
              </AnimatePresence>
            )}
          </Item>
        ))}
    </>
  )
}

export default Menu
